import request from '@/assets/utils/request'
//https://xcxautopartcq.shop.lyxiu.com/
// const base_url = 'http://api.qipei.cc/bms';
// const base_url = 'http://api.jcwh.xyz/bms';
const base_url = 'http://api2.jcwh.xyz/bms';
// export function login(data) {
//     return request({
//         url: base_url+'/login/index',
//         method: 'POST',
//         data
//     })
// }
// export function updatePassword(data) {
//     return request({
//         url: base_url+'/admin/admin/updatePassword',
//         method: 'POST',
//         data
//     })
// }
// export function storeInfo() {
//     return request({
//         url: base_url+'/store/info',
//         method: 'POST'
//     })
// }
// export function storeUpdateInfo(data) {
//     return request({
//         url: base_url+'/store/updateInfo',
//         method: 'POST',
//         data
//     })
// }


// export function type_list() {
//     return request({
//         url: base_url+'//store/getType',
//         method: 'POST'
//     })
// }
// export function type_add(data) {
//     return request({
//         url: base_url+'/StoreType/add',
//         method: 'POST',
//         data
//     })
// }
// export function type_edit(data) {
//     return request({
//         url: base_url+'/StoreType/edit',
//         method: 'POST',
//         data
//     })
// }
// export function type_del(data) {
//     return request({
//         url: base_url+'/StoreType/del',
//         method: 'POST',
//         data
//     })
// }

// export function goods_list(data) {
//     return request({
//         url: base_url+'/StoreGoods/getList',
//         method: 'POST',
//         data
//     })
// }
// export function goods_add(data) {
//     return request({
//         url: base_url+'/StoreGoods/addGood',
//         method: 'POST',
//         data
//     })
// }
// export function goods_edit(data) {
//     return request({
//         url: base_url+'/StoreGoods/editGood',
//         method: 'POST',
//         data
//     })
// }
// export function goods_del(data) {
//     return request({
//         url: base_url+'/StoreGoods/del',
//         method: 'POST',
//         data
//     })
// }

// export function order_list(data) {
//     return request({
//         url: base_url+'/order/getList',
//         method: 'POST',
//         data
//     })
// }
// export function order_deliver(data) {
//     return request({
//         url: base_url+'/order/deliver',
//         method: 'POST',
//         data
//     })
// }

// export function member_list() {
//     return request({
//         url: base_url+'/Member/getList',
//         method: 'POST'
//     })
// }

// export function editUserCredrt(data) {
//     return request({
//         url: base_url+'/Member/editUserCredrt',
//         method: 'POST',
//         data
//     })
// }

// export function getUserCredrtList(data) {
//     return request({
//         url: base_url+'/Member/getUserCredrtList',
//         method: 'POST',
//         data
//     })
// }

// export function getStockList(data) {
//     return request({
//         url: base_url+'/StoreGoods/getStockList',
//         method: 'POST',
//         data
//     })
// }

// export function getIndexData(data) {
//     return request({
//         url: base_url+'/member/getIndexData',
//         method: 'POST',
//         data
//     })
// }

// export function editUserState(data) {
//     return request({
//         url: base_url+'/member/editUserState',
//         method: 'POST',
//         data
//     })
// }

// export function addOffer(data) {
//     return request({
//         url: base_url+'/store/addOffer',
//         method: 'POST',
//         data
//     })
// }

// export function message_peopleList(data) {
//     return request({
//         url: base_url+'/Message/getUserList',
//         method: 'POST',
//         data
//     })
// }
// export function message_list(data) {
//     return request({
//         url: base_url+'/Message/getList',
//         method: 'POST',
//         data
//     })
// }
// export function message_add(data) {
//     return request({
//         url: base_url+'/Message/addMessage',
//         method: 'POST',
//         data
//     })
// }
// export function message_inquiry(data) {
//     return request({
//         url: base_url+'/Message/getInquiry',
//         method: 'POST',
//         data
//     })
// }

// export function getWithDraw(data) {
 
//     return request({
//         url: base_url+'/store/cashList',
//         method: 'POST',
//         data
//     })
// }
// export function getEnquiryList(data) {
 
//     return request({
//         url: base_url+'/store/getInquiryList',
//         method: 'POST',
//         data
//     })

// }
export function getInquiryTotal() {
 
    return request({
        url: base_url+'/store/getInquiryTotal',
        method: 'POST',
    })

}



export function login(data) {
    return request({
        url: base_url+'/login/index',
        method: 'POST',
        data
    })
}
export function updatePassword(data) {
    return request({
        url: base_url+'/admin/admin/updatePassword',
        method: 'POST',
        data
    })
}
export function storeInfo() {
    return request({
        url: base_url+'/store/info',
        method: 'POST'
    })
}
export function storeUpdateInfo(data) {
    return request({
        url: base_url+'/store/updateInfo',
        method: 'POST',
        data
    })
}


export function type_list() {
    return request({
        url: base_url+'//store/getType',
        method: 'POST'
    })
}
export function type_add(data) {
    return request({
        url: base_url+'/StoreType/add',
        method: 'POST',
        data
    })
}
export function type_edit(data) {
    return request({
        url: base_url+'/StoreType/edit',
        method: 'POST',
        data
    })
}
export function type_del(data) {
    return request({
        url: base_url+'/StoreType/del',
        method: 'POST',
        data
    })
}

export function goods_list(data) {
    return request({
        url: base_url+'/StoreGoods/getList',
        method: 'POST',
        data
    })
}
export function goods_add(data) {
    return request({
        url: base_url+'/StoreGoods/addGood',
        method: 'POST',
        data
    })
}
export function goods_edit(data) {
    return request({
        url: base_url+'/StoreGoods/editGood',
        method: 'POST',
        data
    })
}
export function goods_del(data) {
    return request({
        url: base_url+'/StoreGoods/del',
        method: 'POST',
        data
    })
}

export function order_list(data) {
    return request({
        url: base_url+'/order/getList',
        method: 'POST',
        data
    })
}
export function order_deliver(data) {
    return request({
        url: base_url+'/order/deliver',
        method: 'POST',
        data
    })
}

export function addOffer(data) {
    return request({
        url: base_url+'/store/addOffer',
        method: 'POST',
        data
    })
}
export function transfer_list(data) {
    return request({
        url: base_url+'/InquiryOrder/transfer',
        method: 'POST',
        data
    })
}

export function quote_order_list(data) {
    return request({
        url: base_url+'/InquiryOrder/getList',
        method: 'POST',
        data
    })
}

export function quote_order_deliver(data) {
    return request({
        url: base_url+'/InquiryOrder/deliver',
        method: 'POST',
        data
    })
}

//获取询价订单
export function getInquiryOrderInfo(data) {
    return request({
        url: base_url+'/InquiryOrder/getInquiryOrderInfo',
        method: 'POST',
        data
    })
}


export function member_list() {
    return request({
        url: base_url+'/Member/getList',
        method: 'POST'
    })
}

export function editUserCredrt(data) {
    return request({
        url: base_url+'/Member/editUserCredrt',
        method: 'POST',
        data
    })
}

export function getUserCredrtList(data) {
    return request({
        url: base_url+'/Member/getUserCredrtList',
        method: 'POST',
        data
    })
}

export function getStockList(data) {
    return request({
        url: base_url+'/StoreGoods/getStockList',
        method: 'POST',
        data
    })
}

export function getIndexData(data) {
    return request({
        url: base_url+'/member/getIndexData',
        method: 'POST',
        data
    })
}

export function editUserState(data) {
    return request({
        url: base_url+'/member/editUserState',
        method: 'POST',
        data
    })
}


export function message_peopleList(data) {
    return request({
        url: base_url+'/Message/getUserList',
        method: 'POST',
        data
    })
}
export function message_list(data) {
    return request({
        url: base_url+'/Message/getList',
        method: 'POST',
        data
    })
}
export function message_add(data) {
    return request({
        url: base_url+'/Message/addMessage',
        method: 'POST',
        data
    })
}
export function message_inquiry(data) {
    return request({
        url: base_url+'/Message/getInquiry',
        method: 'POST',
        data
    })
}

export function getWithDraw(data) {
 
    return request({
        url: base_url+'/store/cashList',
        method: 'POST',
        data
    })
}
export function getEnquiryList(data) {
 
    return request({
        url: base_url+'/store/getInquiryList',
        method: 'POST',
        data
    })

}

export function getInquiryInfo(data) {
 
    return request({
        url: base_url+'/store/getInquiryInfo',
        method: 'POST',
        data
    })

}

export function getInquiryOrderTotal(data) {
 
    return request({
        url: base_url+'/InquiryOrder/getInquiryOrderTotal',
        method: 'POST',
        data
    })

}



export function uploads(data) {
 
    return request({
        url: base_url+'/Uploads/chatFile',
        method: 'POST',
        data
    })

}
export function getLastMessage(data) {
 
    return request({
        url: base_url+'/Message/getLastMessage',
        method: 'POST',
        data
    })

}

/**
 * 已读
 */
export function updateRead(data) {
 
    return request({
        url: base_url+'/Message/updateRead',
        method: 'POST',
        data
    })

}



