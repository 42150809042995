import axios from "axios";

// const Axios = axios.create({
//     baseURL: process.env.BASE_API,
//     responseType: "json",
//     withCredentials: true,
//     headers: {
//         "Content-Type": "application/x-www-form-urlencoded"
//     }
// });

const Axios = axios.create({
    // baseURL: process.env.BASE_API,
    // responseType: "json",
    // withCredentials: true,
    // headers: {
    //     "Content-Type": "application/x-www-form-urlencoded"
    // },
    timeout: 500000 // 请求超时时间
});

Axios.interceptors.request.use(config => {
    if (sessionStorage.getItem("token")) {
        config.headers['token'] = sessionStorage.getItem("token")
    }
    return config;
},
    function (error) {
        return Promise.reject(error)
    }
);

export default Axios